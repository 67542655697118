<template>
	<main>
		<div class="footer-box">
			<div class="center">
				<div class="footer">
					<div class="item about-box">
						<div class="logo">
							<img :src="require('@/assets/image/logo2.png')" alt="">
						</div>
						<div class="menu">
							<a-link to="/" class="link">首页</a-link>
							<a-link to="/" class="link">案例</a-link>
							<a-link to="/" class="link">服务</a-link>
							<a-link to="/" class="link">关于</a-link>
							<a-link to="/" class="link">联系</a-link>
						</div>
						<div class="desc">怀化建站公司，怀化网站APP建设与优化制作开发公司，湖南森网网络科技有限公司-助力客户一站式上云</div>
						<div class="media">
							<div class="left item"><i class="iconfont icon-gongzhonghao"></i></div>
							<div class="left item"><i class="iconfont icon-douyin"></i></div>
							<div class="left item"><i class="iconfont icon-shejiaotubiao-06"></i></div>
						</div>
					</div>
					<div class="item contact-box">
						<div class="title">联系方式</div>
						<div class="list">
							<div class="item"><i class="iconfont icon-shouhuodizhi"></i><span>{{ config.home_address }}</span></div>
							<div class="item"><i class="iconfont icon-dianhua"></i><span>{{ config.home_phone }} (微信同号)</span></div>
							<div class="item"><i class="iconfont icon-qq"></i><span>{{ config.home_qq_number }}</span></div>
							<div class="item"><i class="iconfont icon-youxiang"></i><span>{{ config.home_email }}</span></div>
						</div>
					</div>
					<div class="item server-box">
						<div class="title">服务类别</div>
						<div class="list">
							<span>LOGO设计</span><span>网站设计</span><span>Web App 设计</span><span>交互动画设计</span><span>3D 建模</span><span>官网建设</span><span>H5 制作</span><span>云会议网站</span><span>商城建设</span><span>门户平台网站</span><span>微信小程序</span><span>QQ 小程序</span><span>支付宝小程序</span><span>百度小程序</span><span>抖音小程序</span><span>安卓 APP</span><span>IOS APP</span><span>景区全景 </span><span>OA 系统</span><span>室内全景</span><span>全景拍摄</span><span>全景展示</span><span>SEO 排名</span><span>SEM 竞价</span><span>微信运营</span><span>查看更多...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="copy">
			<div class="center">
				<span>©2019-{{year}} 湖南森网网络科技有限公司</span>
				<span>powered by hnsw.vip</span>
				<a href="https://beian.miit.gov.cn" ref="nofollow" target="_blank">{{config.home_beian}}</a>
			</div>
		</div>
	</main>
</template>

<script>
import moment from "moment";

export default {
	components:{},
	computed:{
		year(){
			return moment().year()
		},
		config(){
			return this.$store.state.views.config
		}
	},
}
</script>

<style lang="scss" scoped>
.footer-box{background: #333;color:#ccc;padding-top: 50px;font-size: 14px;
	.center{width: 1200px;margin: 0 auto;
		.footer{display: flex;
			.item{flex: 1;padding-right: 50px;}
			.item:nth-last-child(1){padding-right: 0;}
			.about-box{
				.logo{width: 180px;
					img{width: 100%;height:100%;object-fit: contain;}
				}
				.menu{margin-top: 20px;
					.link{display: inline-block;color:#fff;margin-right: 15px;}
					.link:nth-last-child(1){margin-right: 0;}
				}
				.desc{margin-top: 15px;}
				.media{margin-top: 15px;overflow:hidden;
					.item{width: 50px;height:50px;cursor: pointer;line-height: 50px;
						.iconfont{font-size:30px;}
					}
				}
			}
			.contact-box{
				.title{color:#fff;border-bottom: 1px solid rgba(255,255,255,.1);padding-bottom: 20px};
				.list{margin-top: 10px;
					.item{padding:5px 0;
						.iconfont{color:#fff;margin-right: 10px;}
					}
				}
			}
			.server-box{
				.title{color:#fff;border-bottom: 1px solid rgba(255,255,255,.1);padding-bottom: 20px};
				.list{margin-top: 10px;cursor:pointer;
					span{margin-right: 10px;}
				}
			}
		}
	}
}
@media screen and (max-width: 1220px){
	.footer-box{padding-top:m(50px);font-size:m( 14px);
		.center{width: auto;padding: 0 25px;
			.footer{
				.item{padding-right: m(50px);}
				.about-box{
					.logo{width:m(180px);}
					.menu{margin-top: m(20px);
						.link{margin-right:m( 15px);}
					}
					.desc{margin-top: m(15px);}
					.media{margin-top: m(15px);
						.item{width: m(50px);height:m(50px);line-height:m(50px);
							.iconfont{font-size:m(30px);}
						}
					}
				}
				.contact-box{
					.title{padding-bottom: m(20px)};
					.list{margin-top:m(10px);
						.item{padding:m(5px ) 0;
							.iconfont{margin-right:m( 10px);}
						}
					}
				}
				.server-box{
					.title{padding-bottom:m(20px)};
					.list{margin-top:m(10px);
						span{margin-right:m(10px);}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px){
	.footer-box{padding-top:x(50px);font-size:x( 24px);
		.center{width: auto;padding: 0 15px;
			.footer{display: block;
				.item{flex:auto; padding-right: x(50px);}
				.about-box{
					.logo{width:x(180px);}
					.menu{margin-top: x(20px);
						.link{margin-right:x( 15px);}
					}
					.desc{margin-top: x(15px);}
					.media{margin-top: x(15px);
						.item{width: x(100px);height:x(100px);line-height:x(100px);
							.iconfont{font-size:26px;}
						}
					}
				}
				.contact-box{display: none;}
				.server-box{display: none;
					.title{padding-bottom:m(20px)};
					.list{margin-top:m(10px);
						span{margin-right:m(10px);}
					}
				}
			}
		}
	}
}
.copy{padding: 10px 0;background: #000;font-size: 14px;color:#ccc;
	.center{width: 1200px;margin: 0 auto;
		a{color:#ccc;margin-left: 20px;}
	}
}
@media screen and (max-width: 1220px){
	.copy{font-size:m(14px);
		.center{width: auto;padding: 0 25px;}
	}
}
@media screen and (max-width: 768px){
	.copy{font-size:m(17px);
		.center{width: auto;padding: 0 15px;}
	}
}
</style>

