<template>
	<main>
		<div class="header" :class="[(header_active || !is_home) ? 'active' : '']">
			<div class="center">
				<a-link to="/" class="left block logo">
					<img :src="require('@/assets/image/logo2.png')" alt="">
					<img :src="require('@/assets/image/logo.png')" alt="">
				</a-link>
				<div class="right nav-box">
					<a-link :to="item.link" class="left block item" v-for="(item,index) in nav" :class="[route_path === item.link ? 'active' : '']" :key="index">{{item.title}}</a-link>
				</div>
				<div class="right m-nav-btn" @click.stop.prevent="show_m_nav = !show_m_nav">
					<div class="btn" :class="[show_m_nav ? 'active' : '']" >
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</div>
		<div class="m-nav-box" :class="[show_m_nav ? 'active' : '']">
			<div class="mark" @click="show_m_nav = false"></div>
			<div class="wrap">
				<a-link to="/" class="block logo">
					<img :src="require('@/assets/image/logo2.png')" alt="">
				</a-link>
				<div class=" nav-box">
					<a-link :to="item.link" class="block item" v-for="(item,index) in nav" :class="[route_path === item.link ? 'active' : '']" :key="index">{{item.title}}</a-link>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	data() {
		return {
			nav:[
				{title:"首页",link:"/"},
				{title:"关于我们",link:"/about"},
				{title:"服务项目",link:"/server"},
				{title:"作品案例",link:"/cases"},
				{title:"联系我们",link:"/contact"},
			],
			show_m_nav:false,
			header_active:false,
		}
	},
	components:{},
	computed:{
		route_path(){
			return this.$route.path;
		},
		is_home(){
			return this.route_path === '/'
		}
	},
	watch:{
		$route(){
			this.show_m_nav = false;
		}
	},
	mounted() {
		this.listenScroll();
	},
	methods: {
		hideMNav(){
			this.show_m_nav = false;
		},
		listenScroll(){
			var check = ()=>{
				var scroll_top = document.documentElement.scrollTop || document.body.scrollTop;;
				if(scroll_top > 0){
					this.header_active = true;
				}else{
					this.header_active = false;
				}
			}
			check();
			window.addEventListener('scroll',()=>{
				check();
			})
		}
	},
}
</script>

<style lang="scss" type="text/scss" scoped>
.header{overflow:hidden;height:70px;line-height: 70px;position: fixed;top:0;left:0;width: 100%;z-index:$z-header;transition: all .3s ease;
	.center{width: 1200px;margin: 0 auto;height:inherit;
		.logo{height:inherit;display: flex;align-items: center;cursor: pointer;
			img{height:46%; object-fit: contain;}
			img:nth-child(2){display: none;}
		}
		.nav-box{overflow: hidden;
			.item{padding: 0 15px;color:#fff;cursor: pointer;transition: color .3s ease;}
			.item.active{color:$main;}
			.item:nth-last-child(1){padding-right: 0;}
			@media (any-hover:hover){
				.item:hover{color:$main;transition: color .3s ease;}
			}
		}
		.m-nav-btn{height:inherit;align-items: center;padding :0 25px;display: none;
			.btn{width: 26px;cursor: pointer;
				span{display: block;width: 100%;height:2px;background: #fff;margin-bottom: 6px;transition: all .3s ease;}
				span:nth-last-child(1){margin-bottom: 0;}
			}
			.btn.active{
				span:nth-child(1){transform:translate(0, 8px) rotate(45deg);transition: all .3s ease;}
				span:nth-child(2){opacity: 0;transition: all .3s ease;}
				span:nth-child(3){transform:translate(0, -8px) rotate(-45deg);transition: all .3s ease;}
			}
		}
	}
}
.header.active{background: #fff;box-shadow: 0 0 20px 5px rgba(0,0,0,.1);transition: all .3s ease;
	.center{
		.logo{
			img{display: none;}
			img:nth-child(2){display: block;}
		}
		.nav-box{
			.item{color:#333;}
			.item.active{color:$main;}
		}
		.m-nav-btn{
			.btn{
				span{background: #333;}
			}
		}
	}
}
@media screen and (max-width: 1220px){
	.header{height:#{m(70px)};line-height: #{m(70px)};font-size: #{m(16px)};
		.center{width: auto;padding: 0 25px;}
	}
}
@media screen and (max-width: 992px){
	.header{
		.center{padding: 0 0 0 15px;
			.nav-box{display: none;}
			.m-nav-btn{ display: flex;padding: 0 15px;}
		}
	}
}

.m-nav-box{position: fixed;top:0;left:0;width: 100%;height:100%;z-index: $z-header;pointer-events: none;
	.mark{position:absolute;top:0;left:0;width: 100%;height:100%;}
	.wrap{position: absolute;top:0;left:0;width: 50%;height:100vh;overflow: hidden;overflow-y:auto;transform: translateX(-103%);transition: all .3s ease;background: #000;border-right: 2px solid $main;
		.logo{height:#{m(70px)};display: flex;align-items: center;justify-content: center;
			img{height:46%; object-fit: contain;}
		}
		.nav-box{
			.item{color:#fff;height:50px;line-height: 50px;text-align: center;cursor: pointer;}
			.item.active{color:$main;}
		}
	}
}
.m-nav-box.active{pointer-events: auto;
	.wrap{transform: translateX(0);transition: all .3s ease;}
}
</style>

