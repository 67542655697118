<template>
	<div class="error">
		<div class="center">
			<div class="middle">
				<div class="num">{{error.code}}</div>
				<div class="tip">{{error.message}}</div>
				<div class="btn-box">
					<div class="btn" @click="toBack">返回上一页</div>
					<div class="btn" @click="toHome">返回首页</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		error:{}
	},
	methods:{
		toHome(){
			this.$router.push('/')
		},
		toBack(){
			this.$router.back()
		}
	}
}
</script>

<style lang="scss" scoped>
.error{height:700px;text-align: center;
	.center{display: inline-block;
		.middle{height:700px;
			.num{font-size: 80px;}
			.tip{font-size: 18px;line-height:100px;}
			.message{color:#666;}
			.btn-box{margin:50px auto 0;display: flex;align-items: center;
				.btn{padding: 10px 40px; border:2px solid $main;font-size:16px;cursor: pointer;margin-right: 30px;}
				.btn:nth-last-child(1){margin-right: 0;}
				.btn:hover{color:$main;transition: all .2s linear;}
			}
		}
	}
}
</style>
