<template>
	<components-error v-if="error && error.code" :error="error"/>
	<div id="main" v-else :style="{backgroundColor:meta.background || '#fff'}">
		<div class="app-header">
			<components-header/>
		</div>
		<div class="app-container">
			<router-view :key="route_key"></router-view>
		</div>
		<div class="app-footer">
			<components-footer/>
		</div>
	</div>
</template>

<script >
import {site_config} from "@/config";
import {createViewLog} from "@/service/views/common";
import {isDebug} from "@/common/other";
import VConsole from "vconsole";
import ComponentsError from "@/components/views/Error"
import ComponentsHeader from "@/components/layout/view/Header"
import ComponentsFooter from "@/components/layout/view/Footer"
import {config} from "@/api/views";
export default {
	components:{ComponentsError,ComponentsHeader,ComponentsFooter},
	data(){
		return {}
	},
	computed:{
		meta(){
			return this.$route.meta
		},
		route_key(){
			return this.$route.fullPath
		},
		error(){
			return this.$store.state["views/error"]
		}
	},
	watch:{
		$route:{
			handler(n){
				const route_title = this.meta.title;
				document.title =  `${route_title ? route_title+' -' : ''} ${site_config.title}`

				this.$store.commit('views/setError',{});
				createViewLog();
			},
			immediate:true
		}
	},
	mounted() {
		this.checkDebug();
		this.getConfig();
	},
	methods:{
		getConfig(){
			config.get().then((res)=>{
				this.$store.commit('views/setConfig',res.config)
			})
		},
		checkDebug(){
			if(isDebug()){
				const vConsole = new VConsole();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
#main{position: relative;margin: 0 auto; min-height: 100vh;display: flex;flex-direction: column;justify-content: space-between;
	.app-header{flex: 0 0 auto;}
	.app-container{position: relative; flex:1 0 auto;}
	.app-footer{flex: 0 0 auto;}
}
</style>
